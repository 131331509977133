import React, { Component } from "react";
import { graphql, Link } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import ServiceHero from "../components/service-hero";
import CTA from "../components/cta-bar";
import * as Markdown from "react-markdown";
import styled from "styled-components";
import Card from "../components/card";
import PhotoGalleryComponent from "../components/photo-gallery-component";
import SocialReviewsFullwidth from "../components/social-reviews-fullwidth";
import { TestimonialSection } from "../components/testimonial_video";
import { getSchemaSameAs } from "../helpers/schema-json";
import { intersperse } from "../helpers/react";
import { CheckCircle, MapPin } from "react-feather";

const MainContentContainer = styled.div``;
const SidebarContainer = styled.div``;

const WhiteBGSection = styled.section`
  background: #f9f9f9;
  padding: 2.5em 1em;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 2px 4px 0px;
`;
class ServiceCityTemplate extends Component {
  render() {
    function parseContent(string, altCity) {
      if (string) {
        const newString = string.replace(/{{city}}/g, altCity ? altCity : city);
        const secondReplacement = newString.replace(
          /{{citySlug}}/g,
          (altCity ? altCity : city).replace(/\./g, "").toLowerCase().replace(/\s/g, "-") + "-ca"
        );

        return secondReplacement;
      }
    }
    let { pageContext } = this.props;
    const city = pageContext.city;
    let pageData = this.props.data.contentfulServiceAreaContent;
    let citiesList = this.props.data.allContentfulServiceArea.edges;
    let content = parseContent(pageData.content.content);
    let tidbits = "";
    tidbits = pageContext.tidbits;
    let zipCodes = pageContext.zipCodes;

    //replace city in keywords array
    if (pageData.metaKeywordsList) {
      pageData.metaKeywordsList.forEach(function (keyword, index) {
        pageData.metaKeywordsList[index] = parseContent(keyword);
      });
    }

    const TopBar = () => {
      //pageData has the data
      console.log(" pageData.topbar", pageData.topbar);
      const topBar = pageData.topbar || [];
      return topBar.map(({ id, title, cssClasses, content }) => (
        <section key={id} className={cssClasses} dangerouslySetInnerHTML={{ __html: content.content }} />
      ));
    };
    const { testimonials: testimonial_video_posts } = pageData.testimonialSection || { testimonials: [] };

    const SidebarColumn = () => {
      const sideBarCards = pageData.sidebar.map((sidebar, index) => {
        if (sidebar.__typename == "ContentfulSidebar") {
          return <Card key={index} sidebar={sidebar} />;
        } else if (sidebar.__typename == "ContentfulSidebarCollection") {
          const innerCards = sidebar.sidebars.map((sidebar, index) => {
            return <Card key={"inner" + index} sidebar={sidebar} />;
          });
          return innerCards;
        } else return null;
      });

      return sideBarCards;
    };
    const schemaSameAs = getSchemaSameAs(this.props.data.site.siteMetadata.social);
    return (
      <>
        <Layout pageProps={this.props} footerLogoShowcase={pageData.footerLogoShowcase} customSchema>
          <SEO
            title={parseContent(pageData.seoTitle)}
            description={parseContent(pageData.metaDescription)}
            keywords={pageData.metaKeywordsList ? pageData.metaKeywordsList : []}
          />
          <ServiceHero
            pageData={pageData}
            serviceTitle={parseContent(pageData.serviceTitle)}
            heroImage={pageData.heroImage}
            excerpt={parseContent(pageData.excerpt)}
            pageProps={this.props}
            badges={pageData.headerFeaturedBadges}
            specialOffer={parseContent(pageData.specialOffer)}
            offerDisclaimer={parseContent(pageData.offerDisclaimer)}
          />
          {pageData.socialReviewsFullWidth && pageData.socialReviewsFullWidth.slug ? (
            <SocialReviewsFullwidth modalLinkTo={`/reviews/${pageData.socialReviewsFullWidth.slug}/`} />
          ) : null}

          {pageData.photoGalleryRef && pageData.photoGalleryRef.photos ? (
            <PhotoGalleryComponent gallery={pageData.photoGalleryRef} />
          ) : null}

          {<TopBar />}

          {/* <TestimonialSection testimonial_video_posts={testimonial_video_posts} /> */}
          {/* bg-brand-600 text-white */}
          <div>
            <div className="flex flex-wrap  px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto py-8 md:py-12">
              <div className={`${pageData.sidebar ? "md:w-2/3 md:pr-4" : "w-full"} prose md:prose-lg`}>
                {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
                {zipCodes ? (
                  <h3 className="servicedzip">
                    Serviced ZIP Codes:{" "}
                    {intersperse(
                      zipCodes.map((zipCode, i) => zipCode),
                      ", "
                    )}
                    {zipCodes.map((zipCode) => (
                      <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                          __html: `
                            {
                              "@context": "https://www.schema.org",
                              "@type": "PostalAddress",
                              "addressLocality": "${city}",
                              "addressRegion": "NJ",
                              "postalCode": "${zipCode}"
                            }
                           `,
                        }}
                      />
                    ))}
                  </h3>
                ) : null}
                <div className="pt-10 pb-5 bg-white rounded-2xl">
                  <span className="px-8 block text-2xl font-bold text-gray-900 font-display">Other Service Areas</span>
                  <ul className="flex flex-wrap list-none m-0">
                    {citiesList.map((item, i) => {
                      return (
                        <li key={i} className="w-1/2 md:w-1/3 flex space-y-4 md:space-y-6">
                          <div className="flex-shrink-0 mr-2">
                            <CheckCircle fontWeight={900} strokeWidth={3} size={20} className="text-brand-500 mt-2" />
                          </div>{" "}
                          <Link
                            style={{ marginTop: 0, textDecoration: "none" }}
                            className="text-gray-800 underline-none"
                            title={`${parseContent(pageData.serviceTitle, item.node.location)}`}
                            to={`/cities/${item.node.slug}/${pageData.slug}/`}
                          >
                            {item.node.location}
                            <span className="block text-sm font-normal text-gray-500">{pageData.serviceName}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>

              {pageData.sidebar ? (
                <div className="w-full md:w-1/3 md:pl-4 space-y-4 md:space-y-6">
                  {/* <Card /> */}
                  {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d49843.94525730492!2d-121.17892723112209!3d38.66620084666653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x809ae11b89d97a7f%3A0xd44e4c2e31e1ce2a!2sFolsom%2C%20CA!5e0!3m2!1sen!2sus!4v1625607326120!5m2!1sen!2sus" width="1200" height="500" style="border:0; max-width:100%; max-height:500px;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
                  <div className="aspect-ratio-box mb-4 rounded-lg shadow-md">
                    <iframe
                      className="aspect-ratio-box-inside projects-project"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCwXnoLHPbHNOjKkwCoFY8u4RHtqDGp8dM&q=${city}+NJ`}
                      width="100%"
                      frameBorder={0}
                      height="400"
                      loading="lazy"
                    />
                  </div>
                  <SidebarColumn />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  ">
            <div className="mt-4 mb-8 prose">
              <h4>About {city}</h4>
              <section
                className="mb-10 tidbits tidbitssec"
                dangerouslySetInnerHTML={{ __html: tidbits }}
              />
            </div>
          </div>

          <CTA
            title={"Start Your " + parseContent(pageData.serviceTitle) + " Project Today!"}
            customTitle={parseContent(pageData.footerCallToActionHeading)}
            customDesc={parseContent(pageData.footerCallToActionDesc)}
            bgimage={pageData.footerCallToActionImg}
            serviceHeroImage={pageData.heroImage}
            btn={"Get Started!"}
            props={this.props}
          />
        </Layout>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
            {
              "@id": "https://perfectaw.com#HomeAndConstructionBusiness",
              "@context": "https://www.schema.org",
              "@type": "HomeAndConstructionBusiness",
              "name": "Perfect Air & Water Services",
              "url": "https://perfectaw.com/",
              "logo": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
              "image": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
              "description": "Perfect Air & Water Services is a ${city} ${pageData.serviceTitle
              .replace(/{{city}}/g, "")
              .replace(" in ", "")
              .replace(", NJ", "")} specializing in plumbing and HVAC services near you.",
              "openingHours": "Mo, Tu, We, Th, Fr 08:00-18:00",
              "telephone": "+1 888-578-2776",
              "priceRange":"$$",  
              "address": {
                "@type": "PostalAddress", 
                "streetAddress": "170 Dutch Lane Rd",
                "addressLocality": "Freehold",
                "addressRegion": "NJ",
                "postalCode": "07728",
                "addressCountry": "US"
            },
              "contactPoint": {
                  "@type": "ContactPoint",
                  "contactType": "sales",
                  "telephone": "+1 888-578-2776"
              }
          }`,
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
          {
            "@context": "http://schema.org/",
            "@type": "service",
            "servicetype": "${this.props.pageContext.serviceTitle}",
            "provider": {
                "@type": "organization",
                "name": "${this.props.data.site.siteMetadata.title}",
                "url": "${this.props.data.site.siteMetadata.siteUrl + this.props.location.pathname}",
                "logo": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
                "image": "https://images.ctfassets.net/fx9youd8flii/5ET9Kj21NahJ9Jk5a8tCFP/4c1a9abe83b8750e8283098f200708a1/paws-thumb.jpg",
                "address": {
                    "@type": "postaladdress",
                    "addresslocality": "${city}",
                    "addressregion": "NJ",
                    "postalcode": "${this.props.pageContext.zipCode}"
                },
                "contactpoint": {
                    "@type": "contactpoint",
                    "contacttype": "customer support",
                    "telephone": "${this.props.data.site.siteMetadata.companyInfo.phone}",
                    "email": "${this.props.data.site.siteMetadata.companyInfo.email}"
                },
                "sameas": [${schemaSameAs.map((link) => `"${link}"`)}]
            },
            "areaserved": [{
                "@type": "city",
                "name": "${city}",
                "@id": "https://en.wikipedia.org/wiki/${city.replace(/\s/g, "_")},_New_Jersey"
            }],
            "hasoffercatalog": {
              "@type": "offercatalog",
              "name": "${this.props.pageContext.serviceTitle}",
              "itemlistelement": [{
                  "@type": "offercatalog",
                  "name": "${this.props.pageContext.serviceTitle.toLowerCase()} services",
                  "itemlistelement": [
                    ${pageData.schemaItemOffered.map(
                (item) =>
                  `{
                    "@type": "offer",
                    "itemoffered": {
                        "@type": "service",
                        "name": "${item.replace(/{{city}}/g, city)}"
                    }
                }`
              )}]
              }]
          }
      }
    `,
          }}
        />
      </>
    )
  }
}


export default ServiceCityTemplate;

export const pageQuery = graphql`
  query serviceCityQuery($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        companyInfo {
          phone
          email
        }
        social {
          guildquality
          facebook
          yelp
          instagram
          google
          houzz
        }
      }
    }
    contentfulServiceAreaContent(slug: { eq: $slug }) {
      id
      serviceTitle
      serviceName
      seoTitle
      metaDescription
      slug
      excerpt
      specialOffer
      offerDisclaimer
      metaKeywordsList

      socialReviewsFullWidth {
        slug
      }
      testimonialSection {
        testimonials {
          id
          title

          video {
            id
            title
            file {
              url
              contentType
            }
          }
          thumbnail {
            gatsbyImageData(width: 600, quality: 90, placeholder: NONE)
          }
        }
      }
      photoGalleryRef {
        id
        title
        description {
          description
        }
        photos {
          id
          title
          gatsbyImageData(width: 150, quality: 70, aspectRatio: 1.26153846154, placeholder: NONE)
          fullSize: gatsbyImageData(width: 1874, quality: 70, placeholder: NONE)
        }
      }
      headerFeaturedBadges {
        title
        gatsbyImageData(width: 300, placeholder: NONE)
      }
      heroImage {
        gatsbyImageData(width: 1200, quality: 70, placeholder: BLURRED)
      }
      content {
        content
      }
      footerLogoShowcase
      footerCallToActionHeading
      footerCallToActionDesc
      footerCallToActionImg {
        gatsbyImageData(width: 1800, placeholder: NONE)
      }
      topbar {
        id
        title
        content {
          id
          content
        }
        cssClasses
      }
      sidebar {
        __typename
        ... on ContentfulSidebar {
          id
          title
          content {
            id
            content
          }
          testimonialCategory {
            id
            name
          }
          cssClasses
        }
        ... on ContentfulSidebarCollection {
          sidebars {
            id
            title
            content {
              id
              content
            }
            testimonialCategory {
              id
              name
            }
            cssClasses
          }
        }
      }
      schemaItemOffered
    }
    allContentfulServiceArea(sort: { fields: location }) {
      edges {
        node {
          location
          slug
          zipCodes
        }
      }
    }
  }
`;
